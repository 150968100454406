.alignCenter {
  text-align: center;
  justify-content: center;
  letter-spacing: -1px;
}
.alignRight {
  text-align: right;
}
.btn_transparent {
  /* background-image: linear-gradient(#005574, #7aacd6) !important; */
  /* background: inherit !important;#005574 !important; */
  border-color: #a9bac8 !important;
  width: 100%;
}
.btn_colors {
  background-image: linear-gradient(#00536c, #007491) !important;
  border-color: #a9bac8 !important;
  width: 130px;
  margin-right: 15px;
} 
.background_Image{
  width: 100%;
  height: 250px;
  display: 'flex';
  justify-content: 'center';
  border: '2px solid #a9bac8 '!important;
  border-radius: 7px;
}
.background_Image>div{
  border: none !important;
}
.ant-upload.ant-upload-select-picture-card{
  width: 100% !important;
  height: 100% !important;
}
.button_upload {
  background-image: linear-gradient(#00536c, #007491) !important;
  border-color: #a9bac8  !important;
}

.upload>span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_colors2 {
  background-image: linear-gradient(#00536c, #007491) !important;
  border-color: #a9bac8 !important;
  width: 130px;
  margin-right: 15px;
}
.btn_danger{
  float:right
}
@media (max-width:480px){
  .btn_colors2{
    width:160px !important
  }
  .btn_danger{
    width:160px !important
  }
}
@media (max-width:390px){
  .btn_colors2{
    width:120px !important
  }
  .btn_danger{
    width:120px !important
  }
}
@media (max-width:308px){
  .btn_colors2{
    width:90px !important
  }
  .btn_danger{
    width:90px !important
  }
}
/* background-image: linear-gradient(#005574, #7aacd6) !important; */
/* background: inherit !important;#005574 !important; */
.btn-colors:focus,
.btn-colors:hover {
  background: #005574 !important;
  border-color: #a9bac8 !important;
}

.experience_add_btn {
  background-color: white !important;
  width: 100% !important;
  color: steelblue !important;
  border-color: rgb(64, 130, 185) !important;
  font-size: 16px !important;
}

.skip_add_btn {
  background-color: white !important;
  /* color: #ccc !important; */
  /* color: #5683b0 !important; */
  border-color: rgb(157, 156, 156) !important;
  border-width: 1;
  color: #a9bac8 !important;
  width: 175px !important;
}
.skip_add_btn_anniversaries {
  background-color: white !important;
  /* color: #ccc !important; */
  /* color: #5683b0 !important; */
  border-color: "#667D94" !important;
  border-width: 1;
  color: #5683b0 !important;
}
.skip_add_btn:focus,
.skip_add_btn:hover {
  background-color: white !important;
  color: #ccc !important;
  /* color: #5683b0 !important; */
}
.ant-form-horizontal>.ant-row{

  flex-direction: row;
  /* flex-flow: column; */
}
@media(max-width:320px){
  .previous_btn{
    margin-bottom: 12px;
  }
}
@media (max-width: 576px) {
  .alignRight{
    margin-bottom: 12px;
  }
  .responsive_bottom_action_button{
    padding:15px !important;
    justify-content: flex-start !important;
  }
}

/* .Previous_Next_Button_Scope{
  padding: 15px 0px;
     display: flex;
    flex-direction: row;
   justify-content: flex-end
} */
.previous_btn {
  background-color: white !important;
  color: #005574 !important;
  border-color: #005574 !important;
  width: 130px;
  margin-right: 15px;
}
.previous_btn:focus,
.previous_btn:hover {
  background-color: white !important;
  color: #005574 !important;
  /* color: #5683b0 !important; */
}
.experience_add_btn:focus,
.experience_add_btn:hover {
  background-color: white !important;
  color: #5683b0 !important;
}

.image_width_set {
  width: 1185px !important;
  max-width: 100%;
  object-fit: fill;
}
.w_100 {
  width: 100%;
}
.w_100_center {
  width: 100%;
  font-weight: 500;
  text-align: center;
}
.logopadding {
  /* padding: 40px 40px 20px 40px; */
  /* margin-bottom: 20px; */
  margin-top: 40px;
}
.loginText {
  justify-content: center;
  width: 100%;
}
.buttons_tab {
  padding: 15px 0 24px 0;
}
.input_fields {
  border: 1px solid #a9bac8 !important;
  border-radius: 25px !important;
  /* padding: 10px !important; */
  color: #000000cf !important;
  width: 100%;
  font-size: 16px !important;
  height: 41px !important;
  border-width: 1px;
}
.input_fields_combine {
  border-radius: 25px !important;
  /* padding: 10px !important; */
  width: 100%;
  border-width: 1px;
}
.input_fields_select {
  width: 100%;
  border: 1px solid #a9bac8 !important;
  border-radius: 25px !important;
  color: #000000cf !important;
  overflow: hidden !important;
  font-size: 16px !important;
  /* height: 41px !important; */
  /* border-width: 1px; */
}
.input_fields_select:hover {
  border-color: #a9bac8 !important;
  box-shadow: none !important;
}
.input_fields:hover {
  border-color: #a9bac8 !important;
  box-shadow: none !important;
}
.ant-select-selector {
  border-radius: none !important;
}

.input_fields_TextArea {
  border: 1px solid #a9bac8 !important;
  color: #000000cf !important;
  font-size: 16px !important;
  border-radius: 10px !important;
}
.input_fields_TextArea:hover {
  border-color: #a9bac8 !important;
  box-shadow: none !important;
}
.app_alignCenter {
  justify-content: left;
  /* padding: 40px 0px 20px 40px; */
  padding: 40px 40px 20px 40px;
}
.app_padding {
  justify-content: left;
  padding: 40px 20px 20px 20px;
}
.app_store {
  padding-top: 5%;
}
.ant-image-preview-operations-operation:not(:first-child) {
  display: none;
}
.otpInput input {
  width: 60% !important;
  text-align: center !important;
  /* height: 300%; */
  height: 50px;
  outline: none;
  border: 2px solid #BBC3C7 !important;
  opacity: 0.5;
  border-radius: 5px;
}
.top_padding {
  padding-top: 10%;
}

.select_industry_button {
  width: 100%;
  /* height: auto !important; */
  height: 110px !important;
  /* height: auto !important; */
  padding: 10px !important;
  border: 1px solid #1b6889 !important;
  text-align: center;
  line-height: 15px !important;
  font-size: 1vw !important;
}
.select_industry_buttonContainer {
  width: 100%;
  /* min-width: 90px; */
  /* max-width: 100px; */
  /* height: auto !important; */
  height: 90px !important;
  /* min-height: 90px; */
  /* max-height: 110px; */
  /* height: auto !important; */
  padding: 10px 4px !important;
  border: 1px solid #1b6889 !important;
  text-align: center;
  line-height: 15px !important;
  font-size: 1vw !important;
}
.industry_avatort {
  height: 70px !important;
  width: 100% !important;
  border-radius: 10px;
}

.industry_avatortSize {
  height: 44px !important;
  /* height: auto !important; */
  width: 90% !important;
  border-radius: 10px;
}

/* .industry_avatort {
  height: 50px !important;
  width: 50px !important;
} */

.industry_avatort_double_text {
  height: 55px !important;
  width: 100% !important;
}
.industry_text {
  line-height: 12px !important;
  /* font-size: 2.5vw !important; */
  font-size: 11px;
}

::-webkit-input-placeholder {
  color: #a9bac8 !important;
}

@media (max-width: 281px) {
  .experience_add_btn {
    font-size: 10px;
  }
}
