/* @font-face {
    font-family: 'Helvetica';
    src: url('HelveticaNeueMediumExtended.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
} */

/* light */
@font-face {
    font-family: 'Helvetica';
    src: url('HelveticaNeueMediumExtended.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* light */
@font-face {
    font-family: 'san francisco pro';
    src: url('SFPro-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* medium */
@font-face {
    font-family: 'san francisco pro';
    src: url('SFProDisplay-Medium.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}

/* bold */
@font-face {
    font-family: 'san francisco pro';
    src: url('SFProDisplay-Bold.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}

/* semi-bold */
@font-face {
    font-family: 'san francisco pro';
    src: url('SFProDisplay-Semibold.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}