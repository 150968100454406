.ant-tabs-nav-wrap {
  background: #dee5eb;
  height: 3rem;
}
.ant-tabs-content-holder {
  margin-top: 0px;
}
.anchor_element {
  cursor: pointer;
}
.anchor_element:hover {
  color: "#40a9ff";
  cursor: "pointer";
}
.community_form_style>div{
margin-bottom: 5px;
}
.invite_friends_skeleton{
  height:180px
}
.invite_friends_skeleton>.ant-skeleton-header{
  padding-left:16px
}

.invitation_modal_container>.ant-modal-content>.ant-modal-body{
padding:0px;
}
.invitation_modal_container>.ant-modal-content>.ant-modal-header{
  padding:16px 16px 4px;
  }
.community_setting_css>.ant-list-item-meta-avatar>.anticon >svg{
height:20px;
width:20px
}
.community_button_join{
  min-width: 150px;
  padding:4;
  text-transform: capitalize;
}
.community_page_layout{
   padding:0 20px;
}
.Community_manage_Layout{
  padding:2rem
}
.community_manage_action_button>.ant-list-item-meta{
 padding:6px 8px
}
.community_setting_css>.ant-list-item-meta-avatar{
  margin-left:12px
}
.invite_Friends_list:hover{
 opacity:0.4;
 cursor:pointer
}
.community_manage_action_button>.ant-list-item-action{
  margin-left: 0;
  margin-top:0px;
  padding:12px  8px
}


@media(max-width:300px){
  .community_manage_action_button{
     display:block
  }
}
/* .ant-switch-handle::before{
  background-color: #9E9E9E;
} */

/* .manage_req .ant-switch-handle::before{
  background-color: red;
} */
@media only screen and (max-width: 360px) {
  .ant-btn-lg {
    font-size: 13px;
  }
  .community_page_layout{
    padding:0 12px;
 }
 .Community_manage_Layout{
  padding:0.8rem;
 }
 .community_manage_action_button>.ant-list-item-action{
  padding:9px  5px
 } 
 .community_manage_action_button>.ant-list-item-action>li{
  padding:4px 
 }
}

@media (max-width:430px) {
  .ant-btn-lg {
    font-size: 12px;
  }
  .community_manage_action_button{
    display:block
  }
  /* .community_button_join{
    width:auto;
    font-size: 85%;
    padding:0px 10px
  } */
}
@media (max-width: 280px) {
  .ant-btn-lg {
    font-size: 12px;
  }
}
