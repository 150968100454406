ant-select.login_input_fields__2bsvG.ant-select-single.ant-select-allow-clear.ant-select-show-arrow {
  padding: 0 !important;
}
.ant-select-selector {
  /* border-radius: 15px !important; */
  /* height: 43px !important; */
  align-items: center !important;
}
.basic_profile_card {
  border: 1px solid #b1c8de !important;
}
.experince-card-heading {
  background-color: #dfeaf4;
  padding: 10px;
}
.card-body-certificate > .ant-card-body {
  padding: 0px 0px 0px 0px !important;
}
.form-with-padding {
  padding: 24px;
}
.form-with-padding-2 {
  padding: 24px;
}
.ant-select-selection-item {
  display: flex;
}
/* .experience_add_btn{
    background-color: white;
    color: #5683b0;
}
.active-btn-white > .ant-btn-primary:active .ant-btn-primary:hover{
    background-color: white !important;
    color: #5683b0 !important;
} */
/* .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  border-width: 1px;
} */

/* .ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
  background-color: brown;
  border-width: 1px;
}
.ant-select-multiple {
  background-color: blueviolet;
  border-width: 1px brown;
} */
.ant-select:not(.ant-select-customize-input) {
  border: 1px solid #ccc;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgb(134, 184, 218);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: rgb(31, 130, 188);
  border-color: rgb(31, 130, 188);
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: rgb(134, 184, 218);
  border-color: rgb(134, 184, 218);
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-error
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #b41111;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgb(31, 130, 188);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgb(31, 130, 188);
}
.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 10000px;
  height: 1px;
  background: #f0f0f0;
  content: '';
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: rgb(134, 184, 218);
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: rgb(134, 184, 218);
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 0px;
}
.ant-steps-item-title {
  padding-right: 0px;
}
.ant-steps-item-icon {
  margin-right: 0px;
}
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: rgb(134, 184, 218);
}
/* ant modal  */
/* .ant-modal-content {
  border-radius: 1rem;
} */
/* .ant-modal-header {
  border-top-right-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
} */

/* // Business Profile Other */


.left-side-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-img{
  width: 95%;
  height: 25rem;
  border-radius: 5px;
}

.cover-img{
  display: flex;
  flex-direction: column;
}
.ant-image-preview-operations-wrapper {
  position: fixed;
  top: 0;
  right: 10;
  z-index: 1081;
  width: 86%;
  align-items: right;
  justify-content: end;
  left: 0;
}
.ant-image-preview-operations{
  justify-content: center;
  width: 30%;
  position: absolute;
}
.avatar-position{
  position: absolute;
  top: 235px;
  left: 40px;
}
.avatar-position-upload {
  position: absolute;
  top: 235px;
  left: 25px;
}
.edit-cover-icon{
  position: absolute;
  right: -5px;
  bottom: -5px;
}
.ant-upload.ant-upload-select-picture-card{
  width: 100% !important;
  height: 100% !important;
}
.ant-upload-picture-card-wrapper{
  height: 100%;
}

.ReactCrop__image{
  display: block;
  max-width: 100%;
  max-height: 350px;
  -ms-touch-action: none;
  touch-action: none;
}

.user-details{
  margin-top: 2rem;
}

.user{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.user-name{
  width: 50%;
}

.user-follow-button{
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.card-block{
  display: flex;
  flex-wrap: wrap;
}

.user-details-posts{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tabPane-text{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.like-comment-share{
  width: 100%;
  display: flex;
  flex-direction: row;
}

.comment-input{
  width: 100%;
  height: 100%;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
}


.left-side-details{
  display: flex;
  flex-direction: column;
}

.ant-card{
  line-height: 1.2;
}

.heading-info{
  margin: 1rem;
}











#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.1s;
  background-color: black;
}

#myImg:hover {opacity: 0.7;}

/* The Modal (background) */
.modal {
  display: none; 
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.9); 
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  /* max-width: 700px; */
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content, #caption, .ImageHandleBar {  
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)} 
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)} 
  to {transform:scale(1)}
}

/* The Close Button */
.ImageHandleBar li {
  margin: 5px 10px;
}
.ImageHandleBar {
  top:-50px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  background-color: #0000003d;
  opacity: 0.9;
  width: 100%;
  color: #f1f1f1;
  transition: 0.3s;
  list-style: none;
  margin: 0px;
  padding: 0px;
  z-index: 1000;
  position: sticky;

}

.ImageHandleBar li:hover,
.ImageHandleBar li:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}
.col-container {
  text-align: center;
}

.button-circle {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
}

.button-icon {
  width: 40px;
  height: 40px;
  margin-top: -15%; 
  margin-left: -2%; 
}

.text {
  margin-top: 5px;
  font-size: 14px;
 
}
.ant-form-item-explain-error{
  margin-left: 14px;
  /* padding-left: 20px; */
  /* width: 7rem; */
}

.Cover1.ant-form-item-explain-error {
  width: 95% !important;
   text-align: right !important;
 }

.cover_img .ant-form-item-explain-error {
  width: 95%;
  text-align: right;
}
