@import "~antd/dist/antd.css";

ul.social-share {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: "center";
}
.social-share li {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: "center";
}

.flexContainer {
  /* display: flex; */
  min-height: 84px;
  /* height: 84px; */
  /* max-height: 168px; */
  background-image: "linear-gradient(#005574, #7aacd6)";
  background-color: "#7aacd6";
}
.flex-row {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  flex-wrap: inherit;
  /* justify-content:space-between; */
}

.customNavLeft {
  justify-content: flex-end;
}
.flex-direction-row {
  display: flex;
  flex-direction: row;
}
.ant-row-middle {
  align-items: center;
}
.text-center {
  text-align: center;
}
.padding-right-16 {
  padding-right: 16px;
}
.innerElement {
  background: #0bf;
  text-align: center;
  font-size: 30px;
  padding: 20px;
  margin: 5px;
  font-size: 16px;
  color: #fff;
}
.count {
  margin-right: 10px;
  align-content: center;
  border-radius: 15px;
}
.follow-outline {
  border-color: #005574;
  /* margin-right: 16px; */
  width: 100px;
}
.addConnection-outline {
  border-color: #005574;
  color: #005574;
  width: 140px;
}

.outline {
  border-color: #005574;
}
.follow-label {
  font-size: 16px;
  color: #4080c0;
}
.padding-16px {
  padding: 16px;
}
.learn-more {
  font-size: 16px;
  color: #005574;
  text-align: center;
}
.post-connection-content {
  width: 2.5rem;
  border-radius: 4px;
  margin-left: 8px;
  text-align: center;
  background-color: rgb(117, 153, 175);
}
.post-connection-title {
  font-size: 14px;
  color: #005574;
  margin-top: 5px;
}
.homePage_stats > .ant-list-item-meta-avatar {
  margin-right: 6px;
}
.post-connection-count {
  font-size: 16px;
  color: #005574;
  margin-top: -10px;
}
.margin-top-10 {
  margin-top: -10px;
}
.margin-top-4 {
  margin-top: -4px;
}
.margin-top5 {
  margin-top: 5px;
}
.margin-bottom-16 {
  padding-bottom: 16px;
}
.margin-right-16 {
  margin-left: 16px;
}
/* responsive home container */
.customHome_container {
  display: grid;
  grid-template-columns: 29% 42% 29%;
}
.customHome_third_container {
  display: block;
}
/* responsive home container end*/
/* .ant-tabs-tab-active {
  border-bottom-color: #fff;
}
.ant-tabs-tab-btn {
  width: 110px;
  align-items: center;
  border-width: 2px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
  text-shadow: 0 0 0.25px currentColor;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: #fff;
  pointer-events: none;
  margin-bottom: 0px;
  padding: 0px;
  color: #000;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 0px;
  font-size: 16px;
  font-weight: 500;
}
.tab-label {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}
.ant-tabs-nav {
  min-height: 84px;
  height: 84px;
  max-height: 168px;
}
.ant-tabs-large {
  min-height: 84px;
  height: 84px;
  max-height: 168px;
} */
.ant-input-group-wrapper {
  border-radius: 24px;
  padding-right: 24px;
}
/* .ant-input {
  border-radius: 24px;
  border-width: 0px;
} */
.ant-input-search-large .ant-input-search-button {
  height: 40px;
  background-color: #fff;
  border-width: 0px;
  border-radius: 24px;
  margin-right: -10px;
}
.ant-input-search-large {
  background-color: #fff;
  width: 50%;
  margin-bottom: 16px;
}
.ant-badge-dot {
  top: 8px;
  right: 8px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: "transparent";
  border: 0px solid #d9d9d9;
  border-radius: 2px;
  /* transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); */
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0 10px;
  color: #000000cf;
}
.width {
  width: 170%;
}
.Post_image_FullHeight {
  height: 400px;
}
.Post_image_Height {
  height: 100%;
}
.background {
  background-color: "#f6f6f6";
  position: relative;
  height: 100vh;
}
/* .ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 0px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
} */
/* .ant-modal-footer {
  border-top: 0px solid #f0f0f0;
  padding-bottom: 20px;
} */
/* .ant-modal-body {
  padding: 5px 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
} */
.Logout_Popup > .ant-modal-content > .ant-modal-body {
  padding: 10px 24px;
}
.Logout_Popup > .ant-modal-content > .ant-modal-header {
  padding: 16px 24px 0px;
}
.Logout_Popup > .ant-modal-content > .ant-modal-footer {
  padding: 10px 24px 20px;
}

.delete_popup > .ant-modal-content > .ant-modal-body {
  padding: 16px 24px;
}
.delete_popup > .ant-modal-content > .ant-modal-header {
  padding: 16px 24px 0px;
}
.delete_popup > .ant-modal-content > .ant-modal-footer {
  padding: 10px 24px 20px;
}

.crop_ok_btn{
  background-color: #005574;
  border-radius: 20px;
  color: #fff;
  width:80px
}
.crop_modal_content{
  text-align: center;
}

.report-button {
  background-color: #005574;
  border-radius: 20px;
  color: #fff;
}
.ReactCrop_image {
  display: block;
  max-width: 100%;
 max-height: 350px;
 -ms-touch-action: none;
 touch-action: none;
}
.reject-button {
  background-color: #ff1f1f;
  border-radius: 20px;
  color: #fff;
}
.settings-button {
  background-color: #fff;
  width: 7rem;
  color: #4080c0 !important;
  border: 1px solid #4080c0 !important;
}
.settings-button:hover {
  background-color: #005574 !important;
  color: #fff !important;
}
.create-community-button {
  background-color: #005574 !important;
  border: 1px solid #005574 !important;
  border-radius: 20px;
  color: #fff !important;
  margin-top: 16px;
  width: 100%;
  font-weight: bold;
}
.create-community-button:hover {
  color: #005574 !important;
  background: #fff !important;
  border: 1px solid #005574;
}
/* #invitefriend_modal .ant-modal-body{
  padding-left:0 !important ;
  padding-right:0 !important ;
} */
.division {
  width: "100%";
}

.img {
  width: 100%;
  height: 200px;
  /* margin: 5; */
  /* height:fit-content; */
  border-radius: 4px;
  /* background-size:cover; */
}
.post-img-show {
  width: 100%;
  /* height:200px; */
  /* margin: 10px; */
  /* height:fit-content; */
  border-radius: 4px;
  background-size: cover;
}
.img-half {
  width: 350px;
  /* height:200px; */
  /* margin: 10px; */
  /* height:fit-content; */
  border-radius: 4px;
  background-size: cover;
}
.img-third-half {
  width: 350px;
  /* height:400px; */
  /* margin: 10px; */
  /* height:fit-content; */
  border-radius: 4px;
  background-size: cover;
}
.avatar-uploader > .ant-upload {
  margin-top: 15px;
  width: 100%;
  height: 128px;
  border-radius: 6px;
  border: 1px solid #a9bac8;
  margin-right: 0;
  margin-bottom: 0;
}
.Community_textArea{
  font-size: 16px;
  margin-top:15px
} 
.community_Modal>.ant-modal-content>.ant-modal-header{
  padding-bottom: 0px;
}
.avatar-uploader > .ant-upload.ant-upload-select-picture-card:hover{
  border-color: none;
}

.ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #1f82bc;
  border-radius: 13px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: "";
}
.ant-switch-handle {
  position: absolute;
  top: -3px;
  left: 0px;
  width: 26px;
  height: 26px;
  transition: all 0.2s ease-in-out;
}
.ant-switch {
  height: 18px;
  background-color: #adcce3;
}
.demo-nav {
  height: 30px;
  margin-bottom: 16px;
  line-height: 30px;
  background: #f8f8f8;
}
.manage-tab {
  height: 50px;
  background-color: #1f82bc;
}
.create-post-header {
  margin-right: 8px;
  border-width: 0px;
  color: "red";
}
.loginUserName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 180px;
  max-width: 188px;
}
.headingMenuItem {
  /* margin: 15px 5px !important; */
 padding: 15px 20px !important;
  /* color: #fff;  */
}
.ant-menu-submenu > .ant-menu-vertical > .headingMenuItem{
  padding:3px 8px !important;
  margin:8px;
}
.ant-menu-submenu > .ant-menu-vertical > .headingMenuItem:hover {
  color: #016286 !important;
}
.ant-menu-submenu > .ant-menu-vertical > .headingMenuItem img {
  filter: brightness(0);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: none !important;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
}
.headingMenuItem:hover {
  color: #fff !important;
  /* border-bottom: 1.5px solid #fff; */
}

.ant-menu.ant-menu-light .ant-menu-item-selected.headingMenuItem {
  color: #fff;
  border-bottom: 2.5px solid #fff;
}

.ant-input:hover {
  border-color: #f0f0f0;
  /* border-right-width: 0 !important; */
}
.capital_options::first-letter {
  text-transform: capitalize !important;
}
.btn_colors {
  background-image: linear-gradient(#00536c, #007491) !important;
  border-color: #005574 !important;
  width: 100%;
}
.btn_colors2 {
  background-image: linear-gradient(#00536c, #007491) !important;
  border-color: #a9bac8 !important;
  width: 130px;
  margin-right: 15px;
}
.btn_danger{
  float:right
}
@media (max-width:480px){
  .btn_colors2{
    width:160px !important
  }
  .btn_danger{
    width:160px !important
  }
}
@media (max-width:390px){
  .btn_colors2{
    width:120px !important
  }
  .btn_danger{
    width:120px !important
  }
}
@media (max-width:308px){
  .btn_colors2{
    width:90px !important
  }
  .btn_danger{
    width:90px !important
  }
}
/* background-image: linear-gradient(#005574, #7aacd6) !important; */
/* background: inherit !important;#005574 !important; */
.btn_colors:disabled{
  color:white;
  opacity:0.6
}
.btn-colors:focus,
.btn-colors:hover {
  background: #005574 !important;
  border-color: #005574 !important;
}
.cover_img {
  border-radius: 6px;
}

.ant-switch-small .ant-switch-handle {
  margin-top: 6px;
}

/* .ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: #fff !important;
} */

.block_icon_alert {
  text-align: center;
  background: red;
  font-size: 2.5rem;
  color: #fff;
  border-radius: 50%;
}
/* alert header */
.ant-modal-header {
  border-bottom: none;
}

/* Business Profile */
.business-profile-row {
  margin-top: 30px;
}

.left-space {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.left-div-button {
  height: 80px;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.left-about-div {
  margin-left: 5px;
}

.tabs-div {
  width: 95%;
  height: 29rem;
  border-radius: 5px;
  background-color: rgb(240, 239, 239);
}

.tabs-button {
  border-radius: 5px;
  color: rgba(48, 45, 45, 0.575);
  font-weight: 600;
}

.tabPane-text {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
*/ .tabpane-block-div {
  width: 90%;
  margin-top: 40px;
}

.ant-tabs-tab-btn {
  padding: 0px 25px;
}

.circle-progress-bar-div {
  width: 100%;
  height: 9rem;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.circle-progress-bar {
  margin-left: 20px;
}

.circle-text {
  font-size: 12px;
  font-weight: 400;
}

.line-progress-bar-div {
  width: 100%;
  height: 13rem;
  background-color: rgb(255, 255, 255);
  margin-top: 20px;
  padding-top: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.line-text-bar {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.line-text {
  margin: 5px 25px;
}

.post_icon_alert {
  text-align: center;
  background: #36a904;
  font-size: 1.5rem;
  color: #fff;
  border: 10px solid #36a904;
  border-radius: 50%;
  margin-top: 0.5rem;
}
.create-new-post {
  color: white;
  /* opacity: 0.5; */
  width: 100%;
  height: 40px;
  background-image: linear-gradient(#00536c, #007491);
  border-color: #005574;
  border-radius: 40px;
  margin-top: 30px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.tabpane {
  padding: 20px;
}
.ant-input-affix-wrapper > input.ant-input {
  background-color: transparent !important;
}
.open-drawer {
  margin-top: 3px;
  position: absolute;
  z-index: 200;
  background: white;
  padding: 9px;
  box-shadow: 5px 8px 13px #888888;
  box-sizing: border-box;
}
.open-drawer > p {
  text-align: left;
  margin-bottom: 0px;
}
.open-drawer > p > button {
  border: none;
  text-align: left;
  font-size: 14px;
  color: #cdcdcd;
  padding: 0px;
  height: 25px;
}
.open-drawer-b {
  margin-top: 3px;
  position: absolute;
  z-index: 200;
  background: white;
  padding: 9px;
  box-shadow: 5px 8px 13px #888888;
  box-sizing: border-box;
  left: 400px;
}
.open-drawer-b > p {
  text-align: left;
  margin-bottom: 0px;
}
.open-drawer-b > p > button {
  border: none;
  text-align: left;
  font-size: 14px;
  color: #cdcdcd;
  padding: 0px;
  height: 25px;
}
.close-drawer {
  display: none;
}
.edit-profile .ant-modal-content {
  border-radius: none !important;
}
.model-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.model-img > img {
  width: 50px;
  height: 50px;
}
.model-input {
  border: 1px solid #d9d9d9;
  height: 50px;
  text-align: center;
  color: #4080c0;
  border-radius: 50px;
}
.model-button {
  height: 50px;
  border-radius: 24px;
  background: #016286;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  padding-left: 50px;
  padding-right: 50px;
  letter-spacing: 2px;
  color: #ecffff;
}
.model-button-copy {
  height: 50px;
  border-radius: 24px;
  background-image: linear-gradient(#00536c, #007491);
  border-color: #005574;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  padding-left: 46px;
  padding-right: 46px;
  color: #ecffff;
}
.follow_main {
  padding: 10px 30px;
}
.follow_content {
  background: #ffffff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.follow_profile {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.follow_profile .ant-typography {
  margin-left: 15px;
  font-size: 18px;
  font-weight: 400;
}

.follow_button button {
  border-radius: 15px;
  width: 80px;
  background: #2596be;
  border: none;
  color: #fff;
  cursor: pointer;
}
.following_button button {
  border-radius: 15px;
  width: 100px;
  background: transparent;
  border: 1px solid #2596be;
  color: #2596be;
  cursor: pointer;
}
.review_content {
  background: #ffffff;
  padding: 20px;
}
.d-f {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.breadCram {
  margin-left: 19px;
  font-weight: bold;
  font-size: 23px;
}
.pre_cap {
  font-size: 12px;
  opacity: 0.9;
  color: #b5b5b5;
  /* font-weight: bold; */
}
.backgroun {
  height: 100%;
}
.pre_main {
  border-radius: 8px;
  /* margin-left: 20px;
  margin-top: 15px; */
  margin-bottom: 20px;
  display: flex;
}
.pre_consept {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin: 20px 22px;
  border-radius: 5px;
}
.backgroun {
  height: 100vh;
}
.Pre_top {
  background-color: rgba(54, 127, 182, 0.11);
  height: 170px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
}
.pre_point {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  /* font-family: 'HelveticaNeue-Medium'; */
  color: rgba(105, 105, 116, 1);
  margin-left: 7px;
  width: 90%;
}
.pre_row {
  margin-top: 12px;
  display: flex;
}
.pre_plan_btn {
  color: #ebfeff;
  height: 40px;
  border-radius: 24px;
  background-color: #005c7e;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  width: 270px;
}
.checkout_card {
  display: flex;
  margin-top: 20px;
  margin-left: 20px;
  width: 75%;
  background-color: #fff;
  border-radius: 5px;
}
.checkout_in {
  padding: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.confirm_plan_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-left: auto;
}
.w-30 {
  width: 30%;
}
.w-45 {
  width: 45%;
}
.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
}
.radio_btn {
  background: rgb(255, 255, 255);
  border: 1px solid #aabac8;
  padding: 12px;
  width: 320px;
  /* font-weight: bold; */
  border-radius: 4px;
  color: #aabac8;
  font-size: 16px;
  /* fontFamily: 'HelveticaNeue', */
}
.info {
  width: 320px;
  height: 40px;
  background: #dfe4e8;
  margin-bottom: 20px;
  border: 1px solid #465b6c;
  border-radius: 5px;
}
.card_det {
  color: #aabac8;
  border-radius: 25px;
  width: 300px;
  height: 40px;
  border: 1px solid #aabac8;
}
.radio_area {
  margin-left: 20px;
  margin-top: 10px;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
}

.textlabel_UpgradetoPremiumText3 {
  font-style: normal;
  font-weight: bold;
  color: rgba(105, 105, 116, 1);
  /* font-family: 'HelveticaNeue-Medium'; */
  font-size: 16px;
}
.textlabel_UpgradetoPremiumText4 {
  font-style: normal;
  font-weight: normal;
  color: rgba(54, 127, 182, 1);
  /* font-family: 'HelveticaNeue'; */
  font-size: 26px;
  text-transform: capitalize
}
.attrbuted_textlabel_UpgradetoPremiumText3 {
  font-style: normal;
  font-weight: normal;
  /* font-family: 'HelveticaNeue-Medium'; */
  color: rgba(218, 165, 32, 1);
  font-size: 26px;
}
.attrbuted_textlabel_UpgradetoPremiumText4 {
  font-style: normal;
  font-weight: normal;
  /* font-family: 'HelveticaNeue'; */
  color: rgba(105, 105, 116, 1);
  font-size: 13px;
}
.textlabel_headingText {
  font-style: normal;
  font-weight: bold;
  /* color: rgba(255, 255, 255, 1); */
  /* font-family: 'Helvetica-Bold'; */
  font-size: 18px;
}
.textlabel_subheadingText {
  font-size: 14px;
  color: #d0d0d0;
  font-style: normal;
}
.textlabel_subheadingText2 {
  font-size: 12px;
  color: #769ec7;
  margin-top: 2px;
  font-weight: bold;
}
/* Custom Post style start */
.pro-name {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #414141;
}
/* Custom Post style end */

.ant-comment-actions {
  margin-top: 0px;
  margin-bottom: 5px;
  padding-left: 0;
  padding: 0px;
  line-height: 0px;
}

.comment-content-author {
  /* padding-right: 8px; */
  font-size: 16px;
  /* line-height: inherit; */
  color: #5d5d5d;
  font-weight: bold;
  margin-bottom: 0px;
}

.AllCommunityList>.ant-list-item-meta-content>.ant-list-item-meta-title{
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.AllCommunityList>.ant-list-item-meta-content>.ant-list-item-meta-description{
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.replay-botton {
  padding-right: 16px;
  color: black;
  border: none;
  background: transparent;
  box-shadow: none;
}

.editor {
  box-sizing: border-box;
  border: none;
  cursor: text;
  border-radius: 2px;
  background: #fefefe;
  max-height: 80px;
  overflow-y: auto;
  width: 100%;
}

.myProfie_RenderUser_userDetails {
  margin-left: 12px;
}
/* .editor :global(.public-DraftEditor-content) {
  min-height: 500px;
  min-width: 500px;
} */
.options {
  /* margin-bottom: 20px; */
  font-size: 18px;
}
.DraftEditor-root {
  position: relative;
}
.DraftEditor-editorContainer {
  /* position: absolute; */
  top: 0;
}

.location-search-input {
  font-family: "Lato", sans-serif;
  width: 90%;
  min-height: 30px;
  padding: 4px 11px;
  background: none;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  color: #757575;
  font-weight: bold;
}
.location-search-input:focus {
  border: none !important;
}
.input-location input[type="text"]:focus {
  border: none;
}

.input-location {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding-top: 20px; */
}
.input-suggestion {
  font-family: "Lato", sans-serif;
  width: 100%;
  height:100%;
  /* max-width: 250px; */
  /* white-space: pre; */
  padding:6px 12px !important ;
  /* height:40px !important; */
  border-radius:6px;
  background-color: white;
  border-bottom: 1px solid rgb(243, 242, 246 ,0.5);
  display: flex;
  align-items: center;
  font-size: 14px;
  /* font-weight: bold; */
  color: #757575;
}
.input-suggestion-tags {
  font-family: "Lato", sans-serif;
  width: 100%;
  height: 30px;
  /* background-color: red; */
  /* border-bottom: 2px solid rgb(243, 242, 246); */
  display: flex;
  align-items: center;
  font-size: 14px;
  /* border-bottom: 1px solid #BDBDBD; */
  /* font-weight: bold; */
  /* color: #757575; */
  /* margin-left: 1rem; */
}
.ant-mentions-dropdown{
  border-radius: 6px !important;
  box-shadow: none;
}
.ant-mentions-dropdown-menu{
  padding:0px;
  border-radius: 6px !important;
  box-shadow: 0px 2px 7px 0px #888888;
  margin-top:0 !important
}
.autocomplete-dropdown-container {
  z-index: 9999;
  position: absolute;
  overflow-y: auto;
  border-radius: 6px;
  box-shadow:0px 2px 7px 0px #888888;
  /* top:-150; */
  bottom:98%;
  /* padding: 10px; */
  /* height:100% */
  /* height:100%; */
  /* max-height: 200px;
  
  width:100%;
  max-width: 400px; */
  /* overflow:auto */
}
.autocomplete-dropdown-container-2 {
  z-index: 999;
  position: absolute;
  border-radius: 6px;
  box-shadow:0px 2px 7px 0px #888888;
  /* top:-150; */
  top:86%;
  /* padding: 10px; */
  /* height:100% */
  /* height:100%; */
  /* max-height: 200px;
  
  width:100%;
  max-width: 400px; */
  /* overflow:auto */
}
button.e8k2yoa {
  /* margin-right: 10px; */
  margin-top: -7px;
  width: 40px;
  height: 40px;
  font-size: 32px;
  color: rgb(201, 200, 205);
  border: none !important;
  cursor: pointer;
}

.emojiStyling {
  display: inline-block;
}

.customHome_sidebar_layout {
  display: none;
}

.post_btn {
  /* margin-right: 10px; */
  border: none;
  box-shadow: none;
}
.Tagging_data_style:hover{
  text-decoration: underline;
}
.ant-list-item {
  margin-right: 10px;
}
@media (min-width: 2080px) {
  .open-drawer-b {
    margin-top: 3px;
    position: relative;
    z-index: 200;
    background: white;
    padding: 9px;
    box-shadow: 5px 8px 13px #888888;
    box-sizing: border-box;
    top: 0px;
    left: 0px;
  }
}

@media (max-width: 1326px) {
  .customNavLeft {
    justify-content: center;
  }
  .customNavRight {
    display: flex;
    justify-content: center !important;
    width: 80% !important;
    margin: 0 auto;
    /* padding-left: 50px; */
  }
}
/* responsive home container */
@media (max-width: 1282px) {
  .customHome_container {
    grid-template-columns: 40% 50%;
  }
  .customHome_third_container {
    display: none;
  }
}
/* responsive home container end */

@media (max-width: 1024px) {
  .create-new-post {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .customHome_sidebar_layout {
    display: block;
  }
}

@media (max-width: 990px) {
  .customHome_container {
    grid-template-columns: 100%;
  }
}

@media (max-width: 768px) {
  .customHome_sidebar_layout {
    display: block;
  }
  .create-new-post {
    width: 100%;
    height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .pre_main {
    border-radius: 8px;
    /* margin-left: 20px;
    margin-top: 15px; */
    margin-bottom: 10px;
    display: block;
  }
  .checkout_card {
    display: block;
    background-color: #fff;
    margin-top: 20px;
  }
  .checkout_in {
    padding: 8px;
    display: block;
    align-items: center;
  }
  .confirm_plan_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 56px;
  }
  .w-30 {
    width: 100%;
  }
  .w-45 {
    width: 100%;
  }
  .backgroun {
    height: 100%;
  }
  .radio_area {
    margin-left: 20px;
    margin-top: 10px;
    margin-right: 20px;
    display: block;
  }
}
@media (max-width: 620px) {
  .customNavLeft {
    margin-top: 10px;
  }
}
@media (max-width: 576px) {
  .Login_RememberText {
    margin-bottom: 8px;
  }
  .Forgot_password_Text{
    margin-bottom: 12px;
  }
  ._home_responsive_{
    padding: 10px !important;
  }
}

@media (max-width: 512px) {
.model-button-copy {
  margin-top: 12px;
}
}
@media screen and (max-width: 410px) {
  .Post_image_FullHeight {
    height: 200px;
  }
  .Post_image_Height {
    height: 100px;
  }
  .post_btn {
    border: none;
    box-shadow: none;
  }
}
@media screen and (max-width: 430px){
  /* .follow-outline {
  font-size: 90%;
  } */
} 
@media (max-width: 320px) {
  .ant-btn{
    white-space: normal;
    height: fit-content !important  ;
    border-radius: 18px !important;
  }
  /* .ant-row>.EditProfile_action_buttons{
    max-width: 100%;
    width:100%
   } */
   /* .Previous_Next_Button_Scope{
    flex-direction: column;
    width:100%;
    max-width:100%;
    align-items: 'center';
    padding:15px;
    justify-content: flex-start;
  } */
  .create-new-post {
    width: 100%;
    height: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .myProfie_RenderUser {
    justify-content: center;
  }
  .myProfie_RenderUser_userDetails {
    margin-left: 12px;
    /* text-align: center; */
  }
  /* .follow-outline {
    display: none;
    border-color: #005574;
    margin-right: 16px;
    width: 100px;
  } */
  .stats_responsive
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-row
    > div {
    width: 100% !important;
    max-width: 100% !important;
  }
  .commonList_responsive {
    /* flex-wrap: wrap; */
    display: block;
    /* flex-direction: column;display: flex; */
  }
  .commonList_responsive > button {
    margin: 8px 16px 4px;
    width:80%
  }
}

.btn-accept-req-modal,
.btn-accept-req-modal:hover {
  background: linear-gradient(rgb(60, 96, 134) 0%, rgb(53, 135, 196) 100%);
  border-color: rgb(53, 135, 196) 100%;
}

.btn-accept-req-modal,
.btn-reject-req-modal {
  padding: 5px 30px !important;
  font-size: 18px;
  height: unset;
}
.home_invitation_modal>.ant-modal-content>.ant-modal-body{
  padding-bottom: 0;
  padding-top: 0;
}
.home_invitation_modal>.ant-modal-content>.ant-modal-footer{
  padding:18px
}

.customHeader_avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_mobile {
  display: none;
}
.header_web {
  display: flex;
}

.MyProfile_CreatePost > .ant-card-body {
  flex-direction: row;
  display: flex;
  align-items: center;
  border-width: 0px;
  padding: 10px;
}
.MyProfile_createPost_text {
  width: 40%;
}
.MyProfile_createPost_Button {
  width: 60%;
}

@media only screen and (max-width: 425px) {
  .btn-accept-req-modal,
  .btn-reject-req-modal {
    padding: 3px 16px !important;
    font-size: 14px;
    height: unset;
  }
}

@media screen and (max-width: 520px) {
  .MyProfile_CreatePost > .ant-card-body {
    flex-direction: column;
    display: flex;
    align-items: center;
    border-width: 0px;
    padding: 10px;
  }

  .MyProfile_createPost_text {
    width: 100%;
  }
  .MyProfile_createPost_Button {
    margin-top: 6%;
    width: 100%;
  }
  .d-f {
    flex-direction: column;
    align-items: center;
  }
  .follow_profile {
    flex-direction: column;
  }
  .follow_profile_time {
    margin: 10px 20px;
  }
}

@media screen and (max-width: 410px) {
  .customHeader_avatar {
    justify-content: space-between;
    padding: 10px;
    align-items: center;
  }
  .flex-direction-row {
    flex-direction: column;
  }
  .manage-community-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: flex-end;
  }
}

.ant-drawer-body {
  overflow: hidden;
}

.pdfStyle {
  width: 100%;
  height: 60vh;
}

.iframeStyle {
  width: 100%;
  height: 100%;
}

.XlogoStyle {
  height:62px;
  width:62px;
  border-radius:50%;
  margin-bottom:12px;
}
/* .ant-col-24{
  padding-left: 0px !important;
} */
@media screen and (max-width: 992px) {
  .header_mobile {
    display: flex;
  }
  .header_web {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 0.35em;
  height:0.35em;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-thumb {
  background-color: #7aacd6;
  border-radius: 12px;
  z-index: 99999;
  outline: 1px solid #7aacd6;
}
